import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import autocomplete from 'autocompleter'

export default class extends Controller {
  static targets = [ "autocompleteinput", "autocompletehiddeninput", "recordtype" ]

  connect() {
    let url = this.data.get('url')

    if (url == null) {
      url = "/user_suggestions"
    }

    url = new URL(url, window.location.protocol + "//" + window.location.host)

    autocomplete({
      input: this.autocompleteinputTarget,
      preventSubmit: true,
      fetch: function(text, update) {
        text = text.toLowerCase();
        url.searchParams.set('q[name_cont]', text);

        Rails.ajax({
          url: url,
          type: "get",
          success: function(data) {
            update(data.entries)
          }
        })
      },
      onSelect: this.onSelected.bind(this)
    })
  }

  onSelected(item, ele) {
    ele.value = item.label
    this.autocompletehiddeninputTarget.value = item.value
    if (this.hasRecordtypeTarget)
      this.recordtypeTarget.value = item.record_type
  }
}
